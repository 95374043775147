.form-check-input {
    margin-top: -.3em;
}

.my-scroll {
    max-height:300px;
    overflow-y: scroll;
}

.user-query {
    text-decoration: none;
}